class ProgressBar extends HTMLElement {
  constructor() {
    super();
    const orders = this.dataset.order;
    this.init(orders);
  }

  init(orders) {
    const min = Number(this.dataset.feAmount);
    const threshold = Number(this.dataset.threshold);
    if (threshold > orders) {
      this.classList.add('notify');
    } else {
      this.classList.remove('notify');
    }
    if (!min) return;
    if (!orders) return;
    const order = Number(orders);
    if (!order) return;
    if ((order / min) * 100 > 100) {
      this.setProgressBar(100);
    } else {
      this.setProgressBar((order / min) * 100);
    }
  }

  setProgressBar(progress) {
    const p = this.querySelector('.progress');
    if (!p) return;
    const p_bar = p.closest('.progress-bar');
    p.style.width = progress + '%';
    if (!p_bar) return;
    if (progress <= 0) {
      p_bar.classList.add('d-none');
    } else {
      p_bar.classList.remove('d-none');
    }
  }
}

export default ProgressBar;
